@use "../../../scss/btn" as btn;
@use "../../../scss/breakpoints" as b;

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  color: var(--color-primary);
  // background: var(--color-bg-primary);
  background: linear-gradient(
    to top,
    var(--color-bg-tertiary),
    var(--color-bg-primary)
  );

  display: flex;
  flex-direction: column;
  min-height: 100vh; // fallback min
  min-height: 100dvh;
  max-height: 100vh; // fallback max
  max-height: 100dvh;
  overflow-x: hidden;

  main {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    max-height: calc(100dvh - var(--height-navbar));
    margin-top: var(--height-navbar);
    overflow-y: auto;
    overflow-x: hidden;
  }
  .demo_notice {
    display: flex;
    background: #003860;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 10px;
    font-size: large;
    button {
      // @include btn.btn;
      height: fit-content;
      padding: 10px;
      background: transparent;
      :hover {
        // svg {
        fill: darkviolet;
        // }
      }
      svg {
        height: 35px;
        fill: white;
      }
    }
  }

  main.loading div {
    opacity: 0.5;
  }

  .user_acknowledgement_container {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgb(0 0 0 / 83%);
    z-index: 9999;

    .user_acknowledgement {
      display: flex;
      flex-direction: column;
      max-width: 475px;
      color: var(--color-text);
      padding: 100px 20px;
      border: var(--border);
      border-radius: var(--border-radius);
      border: var(--border);
      background: var(--color-bg-primary);
      // box-shadow: 0 0 64px transparent;
      transition: box-shadow 0.5s ease;
      @include b.sm-screen-breakpoint {
        border: none;
      }

      &:hover {
        box-shadow: 0 0 30px var(--color-primary);
      }

      .notice {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;

        ul {
          list-style-type: decimal;
          line-height: 24px;
          padding-inline-start: 16px;
          margin-block-start: 0;
        }
        img {
          height: 100px;
        }
        h1 {
          width: 100%;
          border-bottom: 1px solid var(--color-border);
          display: flex;
          align-items: center;
          margin-block-end: 0;
        }
        svg {
          fill: #33c1ff;
          width: 50px;
        }
        p {
          transition: box-shadow 0.5s ease;
          width: fit-content;
          color: var(--color-text);
        }
      }

      button {
        @include btn.btn;
        height: 50px;
        width: 100%;
      }
    }
  }
}
.fade {
  opacity: 0;
  animation-name: fadeIn;
  animation-duration: 500ms;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
