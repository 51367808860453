@use "../scss/combiner";

.menu-item {
  display: flex;
  align-items: center;
  font-size: 16px;
  border-radius: 10px;
  width: fit-content;
  padding: 5px 10px;
  border: 1px solid var(--color-border);
  cursor: pointer;
  color: var(--color-text);

  &.selected {
    background: var(--color-primary);
    color: var(--color-btn-solid__text);
  }

  &:hover {
    border: 1px solid var(--color-primary);
  }
}
