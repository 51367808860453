.container {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  font-size: 18px;
  background: transparent;
  border: none;
  outline: none;
  color: var(--color-text);

  .input_wrap {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 3px;
    width: 100%;
    font-size: 18px;
    background: transparent;
    border: none;
    outline: none;
    color: var(--color-text);

    label {
      font-size: 16px;
      color: var(--color-text);
    }

    input {
      font-size: 1.3rem;
      background: transparent;
      border: none;
      border-bottom-color: currentcolor;
      border-bottom-style: none;
      border-bottom-width: medium;
      border-bottom: 3px solid var(--color-border);
      border-radius: 0;
      padding: 0;

      outline: none;
      color: var(--color-text);
      &:focus::placeholder {
        color: transparent;
      }
    }

    &:focus::placeholder {
      color: transparent;
    }

    &:focus-within {
      label {
        display: flex;
        color: var(--color-primary);
        visibility: visible !important;
      }
      input {
        border-color: var(--color-primary);
      }
      textarea {
        border-color: var(--color-primary);
      }
    }
  }

  .street {
    width: 100%;
  }

  .unit {
    width: 50%;
  }

  .city {
    width: 100%;
  }

  .state {
    // width: calc(50% - 10px);
    width: 100%;
  }

  .zip {
    // width: calc(50% - 10px);
    width: 100%;
  }

  .county {
    width: 100%;
  }

  .country {
    width: 100%;
  }

  .show {
    display: flex;
    visibility: visible;
  }
  .hide {
    visibility: hidden;
  }

  .error {
    display: flex;
    justify-content: flex-start;
    color: var(--color-error);
  }
}
