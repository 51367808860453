.dropdown-wrap {
  display: flex;
  flex-direction: column;
  gap: 3px;
  position: relative;
  width: 100%;
}

.dropdown-btn {
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  -webkit-user-select: none;
  user-select: none;
  gap: 1rem;
  cursor: pointer;
  width: 100%;
  font-size: 1.2rem;
  border-radius: var(--border-radius);
  background: var(--color-bg-primary);
  border: 1px solid var(--color-border);
}

.dropdown-btn__tags-wrap {
  color: var(--color-text);
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 0.2rem 0 0.2rem 0.2rem;
  font-size: 1rem;
  border-radius: var(--border-radius);
}

.dropdown-btn__icon-wrap {
  display: flex;
}

.dropdown-btn__icon {
  height: 1.5rem;
  fill: var(--color-text);
  transition: 0.1s linear;
}

.dropdown-btn:focus .dropdown-btn__icon {
  fill: var(--color-primary) !important;
}

.dropdown-btn__icon.flipped {
  transform: rotateX(180deg);
}

.dropdown__menu-wrap {
  display: flex;
  flex-direction: column;
  gap: 10px;
  top: 100%;
  position: absolute;
  transform: translateY(4px);
  width: 100%;
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius);
  overflow: auto;
  max-height: 20rem;
  background-color: var(--color-bg-primary);
  z-index: 99;
  color: var(--color-text);
  box-shadow: var(--box-shadow);
  padding: 0.5rem;
}

.dropdown__menu-wrap.show {
  visibility: visible;
}

.dropdown__menu-wrap.hide {
  visibility: hidden;
}

.dropdown__menu__item {
  padding: 0.5rem;
  cursor: pointer;
  border-radius: var(--border-radius);
  width: 100%;
  background: transparent;
  color: var(--color-text);
}

.dropdown__menu__item:hover {
  background-color: var(--color-primary);
  color: var(--color-btn-text);
  /* border: 1px solid var(--color-border); */
}

.dropdown__menu__item:focus {
  background-color: var(--color-primary) !important;
}

.dropdown__menu__item.selected {
  background-color: var(--color-primary);
  color: var(--color-btn-text);
  /* border: 1px solid var(--color-border); */
}

.dropdown-btn__tags-wrap__tag {
  background-color: var(--color-primary);
  color: var(--color-btn-text);
  display: flex;
  align-items: center;
  padding: 0.3rem 0.5rem;
  border-radius: var(--border-radius);
  gap: 0.5rem;
}

.dropdown-btn__tags-wrap__tag__icon-wrap {
  display: flex;
  align-items: center;
  cursor: pointer;
  fill: var(--color-bg-primary);
}

.dropdown__menu__search-box {
  padding: 0.5rem;
  background-color: var(--color-bg-secondary);
}

.dropdown__menu__search-box input {
  width: 100%;
  padding: 0.5rem;
  border: none;
  border-radius: var(--border-radius);
  color: var(--color-text);
  background: var(--color-bg-primary);
}

.dropdown__menu__search-box input:focus-visible {
  outline: 1px solid var(--color-primary);
}

.radio label {
  cursor: pointer;
}
