@use "../../../scss/breakpoints" as b;

.container {
  header {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .landlord-name,
  .listing-name {
    font-weight: 600;
    font-size: 1.2rem;
    margin: 1rem 0;
    color: var(--color-text);
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    padding-bottom: 20px;
  }

  .msg-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    width: 500px;

    label {
      align-self: flex-start;
      padding: 5px;
      color: var(--color-text);
    }

    @include b.md-screen-breakpoint {
      width: 350px;
    }
  }

  .message {
    background: var(--color-bg-primary);
    color: var(--color-text);
    font-size: 18px;
    outline: none;
    padding: 1rem 1.5rem;
    height: 300px;
    width: 500px;
    border: var(--border);
    border-radius: var(--border-radius);
    &:hover,
    &:focus-within {
      border-color: var(--color-border-hover);
    }
    width: 100%;
  }

  button {
    height: 50px;
    width: fit-content;
    border: var(--border);
    border-radius: var(--border-radius);
    background: var(--color-bg-primary);
    color: var(--color-text);
    &:hover {
      border-color: var(--color-border-hover);
      color: var(--color-primary);
    }
  }
}
