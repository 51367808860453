@use "../../../../scss/breakpoints" as b;

@mixin container {
  position: relative;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  border-radius: var(--border-radius);
  border: 1px solid var(--color-border);
  color: var(--color-text);
  background: var(--color-bg-primary);

  &:hover {
    svg {
      fill: var(--color-primary);
    }
  }

  &.used {
    border: 1px solid var(--color-primary);
  }
}

@mixin icon-wrap {
  display: flex;
  height: 25px;
  width: 25px;
}

@mixin icon($transform: unset) {
  height: 100%;
  width: 100%;
  fill: var(--color-text);
  transition: 0.2s ease-in;
  transform: $transform;

  &.open {
    transform: rotateX(180deg);
  }

  &.closed {
    transform: rotateX(0deg);
  }
}

@mixin menu {
  position: absolute;
  display: flex;
  flex-direction: column;
  cursor: default;
  gap: 10px;
  padding: 10px;
  top: 100%;
  left: 0;
  transform: translateY(4px);
  transition: 0.2s ease-in;
  min-width: 100%;
  max-width: fit-content;
  background: var(--color-bg-primary);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius);
  z-index: 1;
  box-shadow: var(--box-shadow);

  &.open {
    visibility: visible;
    opacity: 1;
  }

  &.closed {
    visibility: hidden;
    opacity: 0;
  }
}
