@use "../../dropdownWrappers/scss/combiner";

.menu-section-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  header {
    padding: 5px;
    border-radius: 5px;
    border: none;
  }
}

.menu-section {
  display: flex;
  gap: 5px;
}

.num-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-bg-primary);
  color: var(--color-text);
  font-size: 16px;
  border-radius: 10px;
  min-height: 35px;
  min-width: 35px;
  max-width: 45px;
  max-height: 45px;
  padding: 5px;
  border: 1px solid var(--color-border);

  &:hover {
    border: 1px solid var(--color-primary);
  }

  &.active {
    background-color: var(--color-primary);
    color: var(--color-btn-solid__text);
  }
}
