.container {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 3px;
  width: 100%;
  font-size: 18px;
  background: transparent;
  border: none;
  outline: none;
  color: var(--color-text);

  &.lg {
    width: 100%;
  }

  &.md {
    width: 160px;
  }

  &.sm {
    width: 100px;
  }

  i {
    height: 2rem;
  }

  label {
    font-size: 16px;
    color: var(--color-text);
  }

  input {
    font-size: 1.3rem;
    background: transparent;
    border: none;
    border-bottom-color: currentcolor;
    border-bottom-style: none;
    border-bottom-width: medium;
    border-bottom: 3px solid var(--color-border);
    border-radius: 0;
    padding: 0;

    outline: none;
    color: var(--color-text);
    &:focus::placeholder {
      color: transparent;
    }
  }

  textarea {
    display: flex;
    overflow: auto;
    width: 100%;
    height: 6rem;
    color: var(--color-text);
    background: var(--color-bg-primary);
    padding: 0.5rem;
    font-size: 1rem;
    resize: none;
    border: 1px solid var(--color-border);
    border-radius: var(--border-radius);
    outline: none;

    &:focus::placeholder {
      color: transparent;
    }

    &:focus {
      outline: 1px solid var(--color-primary);
    }
  }

  &:focus::placeholder {
    color: transparent;
  }

  &:focus-within {
    label {
      display: flex;
      color: var(--color-primary);
      visibility: visible !important;
    }
    input {
      border-color: var(--color-primary);
    }
    textarea {
      border-color: var(--color-primary);
    }
  }

  .show {
    display: flex;
    visibility: visible;
  }
  .hide {
    visibility: hidden;
  }

  .subtext {
    display: flex;
    justify-content: space-between;
  }

  .error {
    display: flex;
    justify-content: flex-start;
    color: var(--color-error);
  }

  .price_change_percent {
    color: var(--color-text);
    font-size: 1.2rem;
    border-radius: var(--border-radius);
    padding: 0.5rem;
    border: 1px solid var(--color-border);
    margin-top: 0.2rem;
    text-align: center;
    &.decrease {
      background: var(--color-bg-discount);
    }
    &.increase {
      background: var(--color-price);
    }
  }
}

.visibility_icon {
  cursor: pointer;
  fill: var(--color-border);
  background: transparent;
  height: 100%;
  position: absolute;
  right: 0;
  &:hover {
    fill: var(--color-primary);
  }
}
