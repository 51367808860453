@use "breakpoints" as b;

@mixin container {
  display: flex;
  flex-direction: column;
  gap: 50px;
  justify-content: center;
  align-items: center;
  margin: auto;

  form {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 0 0 50px 0;
    align-items: center;
    width: 340px;
    border-bottom: var(--border);

    h4 {
      position: absolute;
      left: 50%;
      bottom: -30px;
      transform: translateX(-50%);
      color: var(--color-text);
      background: var(--color-bg-primary);
      padding: 0 10px;
    }
  }

  .btn {
    height: 50px;
    width: fit-content;
    padding: 10px;
  }

  .submit_btn {
    margin-top: 15px;
  }

  .more_options {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    margin-bottom: 20px;
  }
}
