.container {
  cursor: pointer;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: fit-content;
  height: 50px;
  font-size: 18px;
  background-color: transparent;
  color: var(--color-text);
  border-radius: var(--border-radius);
  border: 1px solid var(--color-border);
  img {
    height: 100%;
  }
  &:hover {
    border: 1px solid var(--color-primary);
    color: var(--color-primary);
  }
}
