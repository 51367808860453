.container {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;

  height: var(--height-navbar);
  background-color: var(--color-bg-primary);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid var(--color-border);
  justify-content: left;
}

.nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  height: 100%;
  width: 100%;
}

.nav-link {
  color: var(--color-text);
  font-size: 1.2rem;
  text-decoration: none;
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 20px 10px 20px 10px;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-top-left-radius: var(--border-radius);
  border-top-right-radius: var(--border-radius);
  justify-content: center;
  &:hover,
  &:focus,
  &:active,
  &.active {
    color: var(--color-primary);
  }
}

.hamburger {
  height: 100%;
  width: 100%;
  fill: var(--color-text);
}

.logo {
  height: 50px !important;
}

.m-menu-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  background: transparent;

  &.is-open {
    pointer-events: all;
    background: #000000cc;
  }
}

.m-menu {
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;

  transition: 0.3s;
  // border: var(--border);
  // border-radius: var(--border-radius);
  // padding: 10px;
  position: fixed;
  // top: calc(var(--height-navbar));
  background-color: var(--color-bg-primary);
  box-shadow: var(--box-shadow);
  a {
    justify-content: center;
  }
  label {
    // Theme btn
    margin: 20px auto 20px auto;
  }

  &.is-open {
    left: 0;
    opacity: 1;
    visibility: visible;
  }
  &.is-closed {
    opacity: 0;
    visibility: hidden;
  }
}

.m-menu-close-btn {
  display: flex;
  margin-left: auto;
  height: var(--height-navbar);
  width: var(--height-navbar);
  padding: 10px;
  // background-color: var(--color-bg-secondary);
  background-color: transparent;
  svg {
    fill: var(--color-text);
    height: 100%;
    width: 100%;
  }
  // border-bottom-left-radius: var(--border-radius);
  // border-left: var(--border);
  // border-bottom: var(--border);
}

.show-menu-btn {
  height: 100%;
  width: 40px;
  background-color: transparent;
  padding: 5px;
  &:hover svg {
    fill: var(--color-primary);
  }
  &[wobble="1"] {
    animation: wobble-right 1s 1;
  }

  &[wobble="2"] {
    animation: wobble-left 1s 1;
  }
}

@keyframes wobble-right {
  25% {
    transform: rotate(15deg);
  }
  50% {
    transform: rotate(-30deg);
  }
  75% {
    transform: rotate(5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes wobble-left {
  25% {
    transform: rotate(15deg);
  }
  50% {
    transform: rotate(-30deg);
  }
  75% {
    transform: rotate(5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
