@use "../../../scss/access_pages" as ap;

.container {
  @include ap.container;
}

.notice {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 0;
  background: transparent;
  border-radius: var(--border-radius);
  color: var(--color-text);
  svg {
    fill: var(--color-primary);
    width: 40px;
  }
  p {
    max-width: 400px;
    margin: 0;
  }
}
