/* Styles that override default swiper css */


.swiper-container {
  /* width: 1500px !important; */
  width: 100%;
  // height: 400px;
  // border-radius: var;
  overflow: hidden; /* makes rounded border work */
  border-radius: 5px;
}

swiper-container::part(button-prev),
swiper-container::part(button-next) {
  top: 0;
  margin: auto;
  background: var(--swiper-btn-bg);
  height: 100%;
  width: 24px;
  padding: 0 10px;
  color: var(--color-primary);
  $parent: &;
  &.mobile {
    #{$parent}::part(button-prev),
    #{$parent}::part(button-next) {
      width: 0;
    }
  }
}

swiper-container::part(scrollbar) {
  background: var(--color-bg-secondary);
  .swiper-scrollbar-drag {
    background: var(--color-primary);
    color: green;
  }
  color: var(--color-primary);
}

.swiper {
  overflow-y: visible !important;
  overflow-x: hidden;
  border-radius: var(--border-radius) !important;
}

.swiper-slide {
  height: fit-content !important;
  width: fit-content !important;
}

.swiper-pagination-progressbar {
  background: red !important;
}

