.container {
  position: absolute;
  bottom: 0;
  background: var(--color-bg-primary);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // border-top-right-radius: 20px;
  // border-top-left-radius: 20px;
  border-top: var(--border);
  overflow: hidden;
}

.close {
  width: 100%;
  height: 40px;
  background: transparent;
  svg {
    fill: var(--color-text);
    height: 100%;
  }
}

.card {
  width: 375px;
  height: 265px;
  background-color: var(--color-bg-primary);
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  overflow: hidden;

  img {
    height: 200px;
    width: 375px;
    margin: auto;
  }
}

.body {
  display: flex;
  flex-direction: column;
  color: var(--color-text);
  padding: 5px;
  width: 350px;
  margin: auto;
}

.features {
  display: flex;
  align-items: center;
  gap: 5px;
}
