.container {
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: center;
  width: 100%;
  min-height: 100px;
  color: var(--color-text);
  align-items: center;
  border-top: 1px solid var(--color-border);
  margin-top: auto;

  .link {
    color: blue;
    height: 50px;
    img {
      height: inherit;
    }
  }
}
