/* make the map fill its container */
.map {
  height: 300px;
  width: 100%;
}

.zoomControlsContainer {
  display: flex;
  flex-direction: column;
  gap: 0;
  margin: 10px;
}
