.container {
  display: flex;
  justify-content: center;
  position: absolute;
  width: 100%;
  width: 100dvw;
  background: #000000c7;
  height: 100vh;
  height: 100dvh;
  overflow: hidden;
  top: calc(-1 * var(--height-navbar));
  // z-index: 9999;
}

.header {
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  height: var(--height-navbar);
  display: flex;
  justify-content: space-between;
  color: var(--color-text);
  font-size: 2rem;
  padding: 0 10px;
  background: var(--color-bg-primary);
  border-bottom: var(--border);
  z-index: 9999;

  img {
    height: 40px;
  }

  .btns {
    display: flex;
    gap: 5px;
    height: 100%;

    button {
      position: relative;
      color: var(--color-text);
      background: var(--color-bg-primary);
      border-radius: var(--border-radius);
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
      z-index: 2;
      border: var(--border);
      align-self: center;

      svg {
        height: 25px;
        width: 25px;
        fill: var(--color-text);
      }

      label {
        cursor: pointer;
        color: var(--color-text);
      }

      &:hover {
        // background: var(--color-primary);
        border: 1px solid var(--color-primary);
        color: var(--color-primary);
        svg {
          fill: var(--color-primary);
        }
        label {
          color: var(--color-primary);
        }
      }
    }
  }
}

.contents {
  margin-top: var(--height-navbar);
  height: calc(100dvh - var(--height-navbar));
  width: 100dvw;
  overflow-y: scroll;
  background: var(--color-bg-primary);
}

.map-container {
  width: 100%;
  min-height: 300px;
  border: 1px solid var(--color-border);
  border-radius: 5px;
  overflow: hidden;
}

.info {
  display: flex;
  flex-direction: column;
}

.info-card {
  display: flex;
  flex-direction: column;
  color: var(--color-text);
  padding: 10px;
  gap: 10px;
  border-bottom: 1px solid var(--color-border);
}

.basic-info {
  display: flex;
  gap: 10px;
  align-items: center;
  header {
    width: 100%;
    display: flex;
  }
}

.specs {
  display: flex;
  align-items: flex-end;
  gap: 10px;
}

.price {
  font-size: 2rem;
  color: var(--color-text);
  margin: 0;
}

.address {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.images {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.image {
  width: 100%;
  max-height: 80vh;
  object-fit: cover;
}

.description {
  border-bottom: var(--border);
  padding: 10px;
  margin: 0;
  color: var(--color-text);
}

.listed-by {
  border-bottom: var(--border);
  padding: 10px;
  margin: 0;
  color: var(--color-text);
  line-height: 25px;
}

.overview {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 10px;
  flex-grow: 1;
  color: var(--color-text);
}

.features {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.feature {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 30px;
  gap: 10px;
  padding: 10px 0;
  svg {
    fill: #317491;
    height: 25px;
    width: 25px;
  }
}

.share_icon {
  fill: var(--color-text);
}

.share_link_copied {
  position: absolute;
  top: 107%;
  right: 50%;
  transform: translateX(50%);
  z-index: 2;
  border-radius: var(--border-radius);
  padding: 0.5rem 1rem;
  border: 1px solid var(--color-border);
  background: var(--color-bg-primary);
  color: var(--color-text);
  box-shadow: var(--box-shadow);
  font-size: 16px;
}

.options {
  display: flex;
  flex-wrap: wrap;
  grid-area: 5px;
}

// .option {
//   text-wrap: nowrap;
// }
