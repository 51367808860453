@use "../../../scss/breakpoints" as b;

.container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 10px;
  max-width: var(--max-width-content);
  margin: auto;

  @include b.md-screen-breakpoint {
    flex-direction: column;
    align-items: center;
    margin: unset;
  }

  header {
    display: flex;
    font-size: 24px;
    gap: 5px;
    width: 330px;
    align-self: center;
    align-items: center;
    padding: 10px;
    justify-content: center;
    color: var(--color-text);
  }

  #personal-details {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 498px;
    height: -moz-fit-content;
    height: fit-content;
    // border: 1px solid var(--color-border);
    // border-radius: 5px;
    padding: 10px;
    @include b.sm-screen-breakpoint {
      border: none;
    }
  }

  .btns-container {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: center;
  }

  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    height: 50px;
    padding: 10px;
    width: 233px;
    background-color: var(--color-bg-primary);
    color: var(--color-text);
    font-weight: 500;
    font-size: 18px;
    border: 1px solid var(--color-border);
    border-radius: var(--border-radius);
    @include b.xs-screen {
      width: 100%;
    }

    svg {
      height: 100%;
      fill: var(--color-text);
    }

    &:hover {
      color: var(--color-primary);
      svg {
        fill: var(--color-primary);
      }
      border-color: var(--color-primary);
    }
  }

  .listings-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    // border: 1px solid var(--color-border);
    // border-radius: 5px;
    min-width: 320px;
    @include b.sm-screen-breakpoint {
      border: none;
    }
  }

  .listings {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    max-width: 650px;
    justify-content: center;
  }
}
