@use "../../../scss/breakpoints" as b;

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  // width: 100vw;
  height: calc(100vh - var(--height-navbar));
  height: calc(100dvh - var(--height-navbar));
}

.content {
  position: relative;
  display: flex;
  overflow: hidden;
  // Force the map and listing cards container to fill the screen
  // when no listings are found
  flex-grow: 1;
}

.m_list_view_close_icon {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(50%);
  fill: var(--color-primary);
  height: 40px;
  width: 40px;
  svg {
    width: 50px;
  }
}

.filters {
  display: flex;
  border-bottom: 1px solid var(--color-border);
  padding: 5px;
  gap: 5px;
}

.m-filters-container {
  position: fixed;
  top: 0;
  height: 100vh;
  height: 100dvh;
  width: 100%;
  pointer-events: none;
  transition: 0.3s;
  overflow-y: auto;
  z-index: 9999;

  &.is-open {
    pointer-events: all;
    opacity: 1;
    visibility: visible;
    background: #0000005c;
  }
}

.m-filters {
  position: absolute;
  top: 0;
  left: -100%;
  width: 80%;
  height: 100%;
  visibility: hidden;
  opacity: 0;
  transition: 0.3s;
  overflow-y: scroll;
  background: var(--color-bg-primary);
  border-right: var(--border);
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  &.is-open {
    left: 0;
    opacity: 1;
    visibility: visible;
  }
  &.is-closed {
    opacity: 0;
    visibility: hidden;
  }
}

.m-filters-close-icon {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(50%);
  fill: var(--color-primary);
  height: 40px;
  width: 40px;
  svg {
    width: 50px;
  }
}

.m-filters-btn {
  position: absolute;
  top: 0px;
  left: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: 40px;
  width: 40px;
  background-color: var(--color-bg-primary);
  border-right: var(--border);
  border: var(--border);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  // padding: 7px;
  margin: 10px 0 10px 0;
  label {
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
    margin: 0 10px;
    font-size: 18px;
    color: var(--color-text);
  }
  svg {
    fill: var(--color-text);
    width: 30px;
  }
}

.m-filters-close-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 40px;
  max-height: 40px;
  width: 40px;
  margin-left: auto;
  background-color: var(--color-primary);
  border: var(--border);
  border-radius: var(--border-radius);
  label {
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
    margin: 0 10px;
    font-size: 18px;
    color: var(--color-text);
  }
  svg {
    fill: black;
    width: 35px;
    height: 35px;
  }
}

.search-box-container {
  position: relative;
}

.search-box {
  width: 670px;
  height: 50px;
  border-radius: var(--border-radius);
  padding: 0 10px;
  color: var(--color-text);
  background: var(--color-bg-primary);
  border: 1px solid var(--color-border);
  font-size: 1.2rem;

  &:focus {
    outline: 1px solid var(--color-primary) !important;
  }
}

.m-search-box {
  width: 100%;
  height: 55px;
  border-radius: var(--border-radius);
  padding: 0 10px;
  color: var(--color-text);
  background: var(--color-bg-primary);
  border: 1px solid var(--color-border);
  font-size: 1.2rem;

  &:focus {
    outline: 1px solid var(--color-primary) !important;
  }
}

.search-results-header {
  color: var(--color-text);
  padding: 10px;
  font-size: 18px;
  border-bottom: 1px solid var(--color-border);
}

.listing-cards-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
  overflow: hidden scroll;
  color: var(--color-text);
  padding: 0px;
  display: flex;
  flex-direction: column;
  min-width: 670px;
  width: 670px;
  gap: 5px;
  overflow: hidden scroll;
  color: var(--color-text);
  padding: 0px;
  @include b.lg-screen {
    min-width: 345px;
    width: 345px;
  }
}

.listing-cards {
  display: flex;
  flex-direction: column;
  gap: 5px;
  color: var(--color-text);
  height: 100%;
  ul {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    margin: 0px;
    padding: 0px;
    justify-content: center;
    justify-content: flex-start;
    li {
      list-style-type: none;
    }
  }
}

.map-container {
  height: 100%;
  flex-grow: 1;
  // Use width of the listing-card-container
  // width: calc(100vw - 670px);
  background: var(--color-bg-primary);
  // &.mobile {
  //   width: 100vw;
  // }
}

.fade {
  opacity: 0;
  animation-name: fadeIn;
  animation-duration: 500ms;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
