.container {
  display: flex;
  flex-direction: column;
  gap: 3px;
  position: relative;
  width: 100%;
  &:hover .icon {
    fill: var(--color-primary);
  }
}

.btn {
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  -webkit-user-select: none;
  user-select: none;
  gap: 1rem;
  cursor: pointer;
  width: 100%;
  font-size: 1.2rem;
  border-radius: var(--border-radius);
  background: var(--color-bg-primary);
  border: 1px solid var(--color-border);
}

.tags {
  color: var(--color-text);
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 0.2rem 0 0.2rem 0.2rem;
  font-size: 1rem;
  border-radius: var(--border-radius);
}

.icon_container {
  display: flex;
}

.icon {
  height: 1.5rem;
  fill: var(--color-text);
  transition: 0.1s linear;
  &.flipped {
    transform: rotateX(180deg);
  }
}

.menu {
  display: flex;
  flex-direction: column;
  gap: 10px;
  top: 100%;
  position: absolute;
  transform: translateY(4px);
  width: 100%;
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius);
  overflow: auto;
  max-height: 20rem;
  background-color: var(--color-bg-primary);
  z-index: 99;
  color: var(--color-text);
  box-shadow: var(--box-shadow);
  padding: 0.5rem;
  &.show {
    visibility: visible;
  }
  &.hide {
    visibility: hidden;
  }
}

.item {
  padding: 0.5rem;
  cursor: pointer;
  border-radius: var(--border-radius);
  width: 100%;
  background: transparent;
  color: var(--color-text);
  border: 1px solid transparent;
  font-size: 16px;

  &:hover {
    color: var(--color-primary);
    border: 1px solid var(--color-primary);
  }

  &.selected {
    color: var(--color-primary);
    border: 1px solid var(--color-primary);
  }
}

.tag {
  background-color: var(--color-bg-primary);
  color: var(--color-text);
  display: flex;
  align-items: center;
  padding: 0.3rem 0.5rem;
  border: var(--border);
  border-color: var(--color-primary);
  border-radius: var(--border-radius);
  gap: 0.5rem;
  .icon_container {
    display: flex;
    align-items: center;
    cursor: pointer;
    svg {
      fill: var(--color-primary);
    }
  }
}

.search_container {
  padding: 0.5rem;
  background-color: var(--color-bg-secondary);

  input {
    width: 100%;
    padding: 0.5rem;
    border: none;
    border-radius: var(--border-radius);
    color: var(--color-text);
    background: var(--color-bg-primary);
    &:focus-visible {
      outline: 1px solid var(--color-primary);
    }
    label {
      cursor: pointer;
    }
  }
}
