.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  &:focus-within label {
    color: var(--color-primary);
    visibility: visible;
  }
}

.input-wrap {
  position: relative;
  width: 100%;
  display: flex;
}

.label {
  font-size: 16px;
  color: var(--color-border);
  visibility: hidden;
  &.active {
    visibility: visible;
  }
}

.input {
  width: 100%;
  height: 50px;
  font-size: 18px;
  background: transparent;
  border: none;
  outline: none;
  color: var(--color-text);
  padding: 6px 0 6px 30px;
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius);
  &:hover {
    border-color: var(--hover-border-color);
  }
  &:focus::placeholder {
    color: transparent;
  }
  &:focus-within {
    border-color: var(--color-primary);
  }
}

.icon {
  position: absolute;
  left: 6px;
  fill: var(--color-border);
  background: transparent;
  height: 100%;
}
