.street {
  width: 100%;
}

.unit {
  width: 50%;
}

.city {
  width: 100%;
}

.state {
  width: calc(50% - 10px);
}

.zip {
  width: calc(50% - 10px);
}

.county {
  width: 100%;
}

.country {
  width: 100%;
}
