@use "../../../../scss/breakpoints" as b;

@mixin container {
  display: flex;
  flex-direction: column;
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  border: var(--border);

  &:hover .icon {
    fill: var(--color-primary);
  }

  &.used {
    border: 1px solid var(--color-primary);
  }
}

@mixin btn {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  font-size: 1.2rem;
  color: var(--color-text);
}

@mixin icon-wrap {
  display: flex;
}

@mixin icon {
  height: 1.5rem;
  fill: var(--color-text);
  transition: 0.1s linear;

  &.open {
    transform: rotateX(180deg);
  }

  &.closed {
    transform: rotateX(0deg);
  }
}

@mixin menu {
  display: flex;
  flex-direction: column;
  gap: 10px;
  cursor: default;

  &.open {
    animation: expand 0.3s ease-in-out forwards 1;
  }

  &.closed {
    animation: collapse 0.3s ease-in-out forwards 1;
  }
}

@keyframes expand {
  0% {
    height: 0;
    padding: 0;
    opacity: 0;
  }

  100% {
    height: 90px;
    padding: 0 10px 10px 10px;
    opacity: 1;
  }
}

@keyframes collapse {
  0% {
    height: 90px;
    padding: 0 10px 10px 10px;
    opacity: 1;
  }

  100% {
    height: 0;
    padding: 0;
    opacity: 0;
  }
}
