.container {
  display: flex;
  align-self: center;
}

.logo {
  display: flex;
  align-self: center;
  height: 50px !important;
  cursor: pointer;
  :nth-child(1) {
    fill: green;
  }
  :nth-child(n + 2) {
    fill: var(--color-primary) !important;
  }
}
