.container {
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.7803921569);
  height: 100vh;
  overflow: hidden;
  z-index: 1001;
}

.overlay {
  display: flex;
  width: 1436px;
  height: 100dvh;
  min-height: 100vh;
  min-height: 100dvh;
  background: var(--color-bg-primary);
  position: relative;
  border-left: var(--border);
  border-right: var(--border);
}

// .position {
//   position: absolute;
//   display: flex;
// }

.map-container {
  width: 100%;
  min-height: 300px;
  border: 1px solid var(--color-border);
  border-radius: 5px;
  overflow: hidden;
}

.info {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 600px;

  header {
    display: flex;
    height: 64px;
    padding: 10px;
    background: var(--color-bg-primary);
    border-bottom: 1px solid var(--color-border);
    img {
      height: 40px;
    }
  }

  .btns {
    display: flex;
    gap: 5px;
    margin-left: auto;

    button {
      position: relative;
      color: var(--color-text);
      background: var(--color-bg-primary);
      border-radius: var(--border-radius);
      border: 1px solid var(--color-border);
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
      z-index: 2;

      svg {
        height: 25px;
        width: 25px;
        fill: var(--color-text);
      }

      label {
        cursor: pointer;
        color: var(--color-text);
      }

      &:hover {
        background: var(--color-primary);
        border: 1px solid black;
        color: var(--color-btn-solid__text);
        svg {
          fill: var(--color-btn-solid__text);
        }
        label {
          color: var(--color-btn-solid__text);
        }
      }
    }
  }
}

.card {
  display: flex;
  flex-direction: column;
  color: var(--color-text);
  padding: 10px;
  gap: 10px;
  border-bottom: 1px solid var(--color-border);

  .basic {
    display: flex;
    gap: 10px;
    align-items: center;
    header {
      width: 100%;
      display: flex;
    }

    .specs {
      display: flex;
      align-items: flex-end;
      gap: 10px;
    }

    .price {
      font-size: 2rem;
      color: var(--color-text);
      margin: 0;
    }
  }

  .address {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}

.images {
  display: flex;
  flex-direction: column;
  // gap: 5px;
  // flex-wrap: wrap;
  // background: var(--color-bg-secondary);
  flex-grow: 1;
  height: 100dvh;
  overflow-y: scroll;
  img {
    object-fit: cover;
  }
}

.overview {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 10px;
  flex-grow: 1;
  min-width: 600px;
  overflow-y: scroll;
  color: var(--color-text);
  // border-bottom: 1px solid var(--color-border);
  header {
    color: var(--color-text);
    font-size: 2rem;
    padding: 10px 0;
    background: var(--color-bg-primary);
    // border-top: 1px solid var(--color-border);
    border-bottom: 1px solid var(--color-border);
  }

  .features {
    display: flex;
    flex-direction: column;
    gap: 15px;

    .feature {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 30px;
      gap: 10px;
      padding: 10px 0;
      svg {
        fill: var(--color-primary);
        height: 25px;
        width: 25px;
      }
    }
  }
}

.lister {
  line-height: 25px;
}
