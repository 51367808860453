@use "../../../scss/breakpoints" as b;

.header {
  display: flex;
  position: relative;
  width: 100%;
  min-height: 500px;
  background-image: url("./assets/francesca-tosolini-XcVm8mn7NUM-unsplash.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  .welcome {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 10px;
    align-items: center;
    justify-content: center;
    min-width: 100vw;
    min-width: 100dvw;
    min-height: 100%;

    padding: 20px;

    /* CSS gradients over the bg image */
    // background: -moz-linear-gradient(
    //   to bottom,
    //   transparent,
    //   transparent 80%,
    //   var(--color-bg-primary) 100%
    // );

    // background: -webkit-gradient(
    //   to bottom,
    //   transparent,
    //   transparent 80%,
    //   var(--color-bg-primary) 100%
    // );

    // background: linear-gradient(
    //   to bottom,
    //   transparent,
    //   transparent 80%,
    //   var(--color-bg-primary) 100%
    // );
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  p {
    display: flex;
    /* align-items: center; */
    justify-content: center;
    /* position: absolute; */
    top: 0px;
    left: 50%;
    /* transform: translateX(-50%); */
    /* padding: 10px; */
    margin: 0;
    text-align: center;
    color: white;
    font-size: 50px;
    /* border: none; */
    font-weight: bold;
    /* background: linear-gradient(to bottom, rgba(0, 0, 0, 0.85), transparent); */
    /* width: 100%; */
    /* height: 100%; */
    text-shadow: 0px 0px 5px #000;
  }

  &.mobile p {
    // height: 100%;
    // padding: 20px;
  }

  .searchbox {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    gap: 5px;
    background: white;
    width: 500px;
    height: 64px;
    border-radius: var(--border-radius);
    border: 1px solid black;
    color: black;
    font-size: 18px;
    padding: 0 10px;

    svg {
      position: absolute;
      left: 10px;
      height: 25px;
      fill: #00000040;
    }

    input {
      border: none;
      padding-left: 35px;
      flex-grow: 1;
      outline: none;
      height: 100%;
      font-size: 18px;
      background: white;
    }
    &.mobile {
      width: 100%;
      // width: 350px;
    }
    &:focus-within {
      outline: 3px solid var(--color-primary) !important;
    }
  }
}

.searchbox-container {
  // position: absolute;
  // top: 100%;
  // left: 50%;
  // transform: translate(-50%, -50%);
  // border-radius: var(--border-radius);
  // border: black;

  // & :focus-within {
  //   outline: 1px solid var(--color-primary) !important;
  // }

  &.mobile {
    width: 100%;
  }

  // &:hover {
  //   border-color: var(--color-border-hover);
  // }
}

.magnifying_glass {
}

.content {
  max-width: var(--max-width-content);
  display: flex;
  flex-direction: column;
  gap: 64px;
  margin: 64px auto 0 auto;
  // background: var(--color-bg-primary);
  color: var(--color-text);
  padding: 0 10px;
}

.swiper-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  // background-color: var(--color-bg-primary);
  &.mobile {
    padding: 0;
  }
}

.swiper-wrap__mobile {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  h2 {
    color: var(--color-text);
  }
}

.m-cards-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.m-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}

// .card {
//   display: flex;
//   flex-direction: column;
//   height: 390px;
//   width: 334px;
//   background: var(--color-bg-primary);
//   border-radius: var(--border-radius);
//   border: 1px solid var(--color-border);

//   box-shadow: var(--box-shadow);
//   overflow: hidden;

//   img {
//     height: 225px;
//   }
// }

.card-body {
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 10px;
  flex-grow: 1;
  header {
    text-align: center;
    font-size: 1.5rem;
    font-weight: 500;
    font-family: cursive;
    margin: 0;
    padding: 5px;
    color: var(--color-text);
    background: var(--color-bg-secondary);
    border-radius: 5px;
  }
  p {
    margin: 0;
  }
}

.btns {
  display: flex;
  gap: 10px;
  margin: 64px auto 64px auto;
  justify-content: center;
  @include b.md-screen-breakpoint {
    flex-direction: column;
    align-items: center;
  }
}

.btn {
  cursor: pointer;
  border-radius: var(--border-radius);
  padding: 0.85rem 2rem;
  font-weight: 600;
  font-size: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-text);
  border: 1px solid var(--color-border);
  background: transparent;
  margin-top: auto;
  max-width: 365px;
  &:hover {
    border-color: var(--color-primary);
    color: var(--color-primary);
  }
  @include b.md-screen-breakpoint {
    width: 350px;
  }
}

.fade {
  opacity: 0;
  animation-name: fadeIn;
  animation-duration: 500ms;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.swiper {
  width: 1120px;
  height: 415px;
  border-radius: var(--border-radius);
  overflow-y: visible !important;
}

.swiper-slide {
  height: fit-content !important;
  width: fit-content !important;
}

.swiper-container {
  &::part(button-prev),
  &::part(button-next) {
    top: 0;
    margin: auto;
    background-color: #000000b0;
    height: 100%;
    width: 50px;
    padding: 0 10px;
    color: var(--color-border);
  }
}

swiper-container::part(button-prev) {
  left: 0;
}

swiper-container::part(button-next) {
  right: 0;
}
