@use "sass:map";

$active: (
  "color": var(--color-primary),
  "background": var(--color-bg-primary),
  "border-color": var(--color-bg-primary),
);

@mixin btn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  // background: var(--color-bg-primary);
  background: transparent;
  color: var(--color-text);
  border: var(--border);
  border-radius: var(--border-radius);
  fill: var(--color-text);

  &.active {
    color: map.get($active, "color");
    border-color: map.get($active, "color");
    svg {
      fill: map.get($active, "color");
    }
  }

  &:hover {
    color: map.get($active, "color");
    border-color: map.get($active, "color");
    svg {
      fill: map.get($active, "color");
    }
  }
}
