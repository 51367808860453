@use "../scss/flex" as f;
@use "../../../../scss/btn" as b;

.container_wrap {
  display: flex;
  flex-direction: column;
  gap: 3px;
  width: 100%;
}

.container {
  @include f.container;
  width: 100%;
  // padding: 0 10px;
}

.menu_toggle_btn {
  @include b.btn;
  @include f.btn;
  width: 100%;
  // Setting height will not allow tags to stack up vertically
  border: none;
  background: none;
  outline: none;
  font-size: 18px;
  color: var(--color-text);
  text-align: left;
  padding: 10px;
  cursor: pointer;
}

.icon_container {
  @include f.icon-wrap;
}

.icon {
  @include f.icon;
}

.menu {
  @include f.menu;
  border-top: var(--border);

  button {
    @include b.btn;
    @include f.btn;
    width: 100%;
    height: 40px;
    border: none;
    background: none;
    outline: none;
    font-size: 18px;
    color: var(--color-text);
    text-align: left;
    padding: 10px;
    cursor: pointer;
    border: var(--border);
    border-color: transparent;
    &.selected {
      border-color: var(--color-primary);
      color: var(--color-text);
    }
  }
}

.tag_container {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.tag {
  background-color: var(--color-bg-primary);
  color: var(--color-text);
  display: flex;
  align-items: center;
  padding: 0.3rem 0.5rem;
  border: var(--border);
  border-radius: var(--border-radius);
  gap: 0.5rem;
  .icon_container {
    display: flex;
    align-items: center;
    cursor: pointer;
    svg {
      fill: var(--color-border);
    }
    &:hover {
      svg {
        fill: var(--color-primary);
      }
    }
  }
}
